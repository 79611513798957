import type {FC} from 'react'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {Label, useFormControlId} from '../form-control'
import {Text} from '../text'

type ComponentWrapperProps = Pick<TextareaProps, 'className'>

const ComponentWrapper = styled.div<ComponentWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing6};
    width: 100%;
`

export interface BaseTextareaProps {
    /**
     * Name is used for accessibility and is required
     */
    name: string

    /**
     * Current value of the textarea
     * @default null
     */
    value?: string | null

    /**
     * Should it render the input as holding an invalid value
     * @default false
     */
    isInvalid?: boolean

    /**
     * Render prop for error message
     */
    renderError?: Function

    /**
     * Should the component be disabled. Meaning no interaction possible
     */
    disabled?: boolean

    /**
     * Display a label above the textarea
     */
    label?: string

    /**
     * Display count value in lower right corner
     * @default false
     */
    showCounter?: boolean

    /**
     * Set a fixed height with a scrollbar
     */
    fixedHeight?: string

    /**
     * aria-label can be added to the textarea for further details,
     * though accessibility should be handled out of the box by using the name property
     */
    'aria-label'?: string

    /**
     * className is used to override styles of the component
     */
    className?: string
}

export interface TextareaProps
    extends BaseTextareaProps,
        Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'name'>,
        React.RefAttributes<HTMLTextAreaElement> {}

const StyledTextarea = styled.textarea<{$fixedHeight?: string}>`
    grid-area: input;
    box-sizing: border-box;
    width: 100%;
    min-height: 24px;
    text-indent: 1px;
    color: ${tokens.colorContentInteractive};
    font-weight: inherit;
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight2};
    font-family: inherit;
    background-color: ${tokens.colorBackgroundInteractive};
    border: none;
    outline: none;
    transition: ${tokens.motionWithinSmallShort};
    appearance: none;
    resize: none;
    overflow-y: hidden;

    &:hover {
        color: ${({disabled}) => !disabled && tokens.colorContentInteractiveHover};
    }

    &::placeholder {
        color: ${tokens.colorContentInteractivePlaceholder};
    }

    ${({$fixedHeight}) =>
        $fixedHeight &&
        `
            height: ${$fixedHeight};
            overflow-y: auto;
        `}

    ${({disabled}) =>
        disabled &&
        `
            cursor: default;
            color: ${tokens.colorContentInteractiveDisabled};
            background-color: ${tokens.colorBackgroundInteractiveDisabled};

            &::placeholder {
                color: ${tokens.colorContentInteractiveDisabled};
            }
        `}
`
interface TextareaWrapperProps {
    $isInvalid?: boolean
    $disabled?: boolean
}

const focusBorders = css<TextareaWrapperProps>`
    &:focus-within {
        border-color: transparent;

        /* Fallback for Windows High Contrast Mode, as it hides box-shadows */
        outline: 3px solid transparent;
        box-shadow: 0 0 0 3px
            ${(props) =>
                props.$isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus};
        transition: ${tokens.motionWithinSmallLong};
    }

    &:hover:focus-within {
        border-color: transparent;
    }

    &:hover:not(:focus-within) {
        border: 1px solid
            ${(props) =>
                props.$isInvalid ? tokens.colorShadowFocusInvalid : tokens.colorShadowFocus};
    }
`

const TextareaWrapper = styled.div<TextareaWrapperProps>`
    position: relative;
    display: grid;
    grid-template-areas: 'input postfix';
    grid-template-columns: 1fr auto;
    align-items: center;
    overflow: hidden;
    transition: ${tokens.motionWithinSmallShort};
    padding: ${tokens.spacing8} ${tokens.spacing12};
    padding-right: ${tokens.spacing20};
    border: ${(props) => {
        if (props.$disabled) {
            return `1px solid ${tokens.colorBorderInteractiveDisabled}`
        }

        if (props.$isInvalid) {
            return `1px solid ${tokens.colorBorderNegative}`
        }

        return `1px solid ${tokens.colorBorderInteractiveQuiet}`
    }};
    border-radius: ${tokens.arc8};
    background-color: ${(props) =>
        props.$disabled
            ? tokens.colorBackgroundInteractiveDisabled
            : tokens.colorBackgroundInteractive};

    ${({$disabled}) => !$disabled && focusBorders}
`

// This is needed to default the textarea to height 40 on the wrapper. Similar to Input
const MIN_HEIGHT = 24

export const Textarea: FC<TextareaProps> = ({
    name,
    onChange,
    showCounter = false,
    value,
    label,
    className,
    isInvalid,
    renderError,
    maxLength,
    disabled,
    fixedHeight,
    'aria-label': ariaLabel,
    id: inputId,
    ...props
}) => {
    const id = useFormControlId(inputId)

    const [counter, setCounter] = useState<number>(0)

    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const handleResize = useCallback(() => {
        if (textAreaRef?.current && !fixedHeight) {
            /* Reset the height
             * This is needed to resize the textarea vertically
             **/
            textAreaRef.current.style.height = ''

            const scrollHeight = textAreaRef.current.scrollHeight
            const newHeight = Math.max(scrollHeight, MIN_HEIGHT)
            textAreaRef.current.style.height = newHeight + 'px'
        }
    }, [fixedHeight, textAreaRef])

    const updateAndResize = useCallback(() => {
        const updateValue = value ?? textAreaRef?.current?.value

        const toNumber = Number(updateValue?.length)

        setCounter(isNaN(toNumber) ? 0 : toNumber)
        handleResize()
    }, [setCounter, handleResize, value])

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize])

    useEffect(() => {
        updateAndResize()
        const current = textAreaRef?.current
        current?.addEventListener('input', updateAndResize)

        return () => current?.removeEventListener('input', updateAndResize)
    }, [textAreaRef, updateAndResize])

    const isValueLengthOverLimit = Boolean(maxLength && value && value.length > maxLength)

    const counterId = `counter-${id}`

    return (
        <ComponentWrapper className={className} data-telescope="textarea">
            {label && (
                <Label
                    as="label"
                    // Will be fixed in https://linear.app/pleo/issue/WEB-789/replace-react-docgen-typescript-with-react-docgen
                    // @ts-expect-error htmlFor prop missing
                    htmlFor={id}
                >
                    {label}
                </Label>
            )}
            <TextareaWrapper $isInvalid={isInvalid || isValueLengthOverLimit} $disabled={disabled}>
                <StyledTextarea
                    id={id}
                    name={name}
                    rows={1}
                    aria-controls={showCounter ? counterId : undefined}
                    aria-label={ariaLabel}
                    value={value === null ? '' : value}
                    onChange={onChange}
                    maxLength={maxLength}
                    disabled={disabled}
                    ref={textAreaRef}
                    $fixedHeight={fixedHeight}
                    {...props}
                />
                {showCounter && <Counter id={counterId} value={counter} maxLength={maxLength} />}
            </TextareaWrapper>
            {renderError && renderError()}
        </ComponentWrapper>
    )
}

Textarea.displayName = 'Textarea'

interface CounterProps {
    value: number
    id?: string
    maxLength?: number
}

const StyledCounter = styled(Text).attrs({
    role: 'status'
})`
    position: absolute;
    right: ${tokens.spacing6};
    bottom: 0;
    font-size: ${tokens.fontSmall};
`

const Counter: FC<CounterProps> = ({value, maxLength, id}) => {
    const displayedValue = maxLength ? maxLength - value : value
    return <StyledCounter id={id}>{displayedValue}</StyledCounter>
}
