import type {ButtonHTMLAttributes, RefAttributes} from 'react'
import React, {forwardRef} from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {NakedButton} from '../button'
import {focusRing} from '../focus-ring'
import type {TooltipProps} from '../tooltip'
import {Tooltip} from '../tooltip'

type BaseTooltipProps = Omit<TooltipProps, 'children' | 'aria-label' | 'content'>

type TooltipPropsWithContent = BaseTooltipProps & {
    /**
     * The content of the tooltip.
     * If `aria-label` is not provided, this content will be used also as a fallback when the tooltip is closed.
     * This prevents assistive technologies from announcing both `aria-label` and `aria-describedby`
     * simultaneously, as `aria-describedby` is added only when the tooltip is open.
     * See this radix-ui issue: https://github.com/radix-ui/primitives/issues/2988
     */
    content: React.ReactNode
    dangerouslyOmitTooltip?: false
    /**
     * Defines a string value that labels the current element.
     * It will get removed when the tooltip is open.
     * This prevents assistive technologies from announcing both `aria-label` and `aria-describedby`
     * simultaneously, as `aria-describedby` is added only when the tooltip is open.
     * See this radix-ui issue: https://github.com/radix-ui/primitives/issues/2988
     */
    'aria-label'?: string
}

type TooltipPropsWithoutContent = BaseTooltipProps & {
    content?: never
    /**
     * If true, the `content` prop can be omitted. Instead, an `aria-label` will be required.
     * This setting should only be enabled when a tooltip is not required to meet accessibility standards.
     * See here for more information: https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA14#examples
     */
    dangerouslyOmitTooltip: true
    /**
     * Defines a string value that labels the current element.
     */
    'aria-label': string
}

type IconButtonTooltipProps = TooltipPropsWithContent | TooltipPropsWithoutContent

export interface IconButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        RefAttributes<HTMLButtonElement> {
    // Add an Icon to be rendered
    Icon: React.ComponentType
    // The Variant of the Icon Button.
    variant: 'secondary' | 'primary' | 'quiet'
    // The size of the Icon Button
    size?: 'extraSmall' | 'small' | 'medium' | 'large'
    /** Pass Tooltip Props to add a tooltip to the button. Accepts the same props as the tooltip component.
     * See the [tooltipProps](https://telescope.pleo.io/components/tooltip/tooltip.code#api-reference)
     */
    tooltipProps: IconButtonTooltipProps
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

const primaryVariantStyling = css`
    ${focusRing('offset')}
    background-color: ${tokens.colorBackgroundInteractiveInverse};
    color: ${tokens.colorContentInteractiveInverse};

    &:hover {
        color: ${tokens.colorContentInteractiveInverseHover};
        background-color: ${tokens.colorBackgroundInteractiveInverseHover};
    }

    &:disabled {
        background-color: ${tokens.colorBackgroundInteractive};

        &:hover {
            background-color: ${tokens.colorBackgroundInteractive};
        }
    }
`

const quietVariantStyling = css`
    color: ${tokens.colorContentInteractiveQuiet};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }
`

const largeStyling = css`
    width: 48px;
    height: 48px;

    [data-telescope='icon'] {
        width: 24px;
        height: 24px;
    }
`

const smallStyling = css`
    width: 32px;
    height: 32px;

    [data-telescope='icon'] {
        width: 16px;
        height: 16px;
    }
`

const extraSmallStyling = css`
    width: 24px;
    height: 24px;

    [data-telescope='icon'] {
        width: 16px;
        height: 16px;
    }
`

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({tooltipProps, Icon, variant, size, skeleton = false, ...props}, ref) => {
        const [tooltipIsOpen, setTooltipIsOpen] = React.useState(tooltipProps.open)

        const tooltipContent =
            typeof tooltipProps.content === 'string' ? tooltipProps.content : undefined

        const ariaLabel = tooltipProps['aria-label'] ?? props['aria-label'] ?? tooltipContent

        const handleTooltipChange = React.useCallback(
            (isOpen: boolean) => {
                setTooltipIsOpen(isOpen)
                tooltipProps.onOpenChange?.(isOpen)
            }, // eslint-disable-next-line react-hooks/exhaustive-deps
            [tooltipProps.onOpenChange]
        )

        const iconButton = (
            <IconButtonStyles
                {...props}
                $variant={variant}
                $size={size}
                aria-label={
                    tooltipProps.dangerouslyOmitTooltip || !tooltipIsOpen ? ariaLabel : undefined
                }
                data-telescope="icon-button"
                ref={ref}
                skeleton={skeleton}
            >
                <Icon />
            </IconButtonStyles>
        )

        if (tooltipProps.dangerouslyOmitTooltip) {
            return iconButton
        }

        return (
            <Tooltip {...tooltipProps} onOpenChange={handleTooltipChange}>
                {iconButton}
            </Tooltip>
        )
    }
)

IconButton.displayName = 'IconButton'

IconButton.defaultProps = {
    size: 'medium'
}

export const IconButtonStyles = styled(NakedButton)<{
    $variant: IconButtonProps['variant']
    $size?: IconButtonProps['size']
}>`
    ${focusRing('regular')}
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${tokens.circle};
    cursor: pointer;
    transition: background-color ${tokens.motionWithinSmallShort},
        color ${tokens.motionWithinSmallShort};
    background-color: transparent;
    color: ${tokens.colorContentInteractive};
    width: ${tokens.heightInputAndButton};
    height: ${tokens.heightInputAndButton};
    flex-shrink: 0;

    [data-telescope='icon'] {
        width: 24px;
        height: 24px;
    }

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        background-color: ${tokens.colorBackgroundInteractiveTransparentHover};
    }

    ${({$size}) => $size === 'extraSmall' && extraSmallStyling}
    ${({$size}) => $size === 'small' && smallStyling}
    ${({$size}) => $size === 'large' && largeStyling}
    ${({$variant}) => $variant === 'primary' && primaryVariantStyling}
    ${({$variant}) => $variant === 'quiet' && quietVariantStyling}

    &:disabled {
        color: ${tokens.colorContentInteractiveDisabled};
        cursor: not-allowed;

        &:hover {
            background-color: transparent;
        }
    }
`
