import type {HTMLAttributes} from 'react'
import React from 'react'
import styled from 'styled-components'

export interface VisuallyHiddenProps {
    /**
     * The HTML element to render as. If the hidden content is not phrasing content, set to 'div'
     * @default 'span'
     */
    as?: 'div' | 'span'
    /**
     * The content to be visually hidden
     */
    children: React.ReactNode
}

export const VisuallyHidden = ({
    children,
    as,
    ...props
}: VisuallyHiddenProps & HTMLAttributes<HTMLElement>) => {
    return (
        <Container as={as} {...props}>
            {children}
        </Container>
    )
}

const Container = styled.span`
    /* shrink to a 1px square */
    width: 1px;
    height: 1px;

    /* hide any resulting overflow */
    overflow: hidden;

    /* clip the element to remove any visual trace */
    clip: rect(0 0 0 0); /* for IE only */
    clip-path: inset(50%);

    /* remove from page flow so it doesn’t affect surrounding layout */
    position: absolute;

    /* ensure proper text announcement by screen readers */
    white-space: nowrap;
`
