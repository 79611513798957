import React, {forwardRef} from 'react'
import type {AnchorHTMLAttributes} from 'react'
import type {LinkProps as RouterLinkProps} from 'react-router-dom'
import type {DataAttributes} from 'styled-components'
import {css} from 'styled-components'
import styled from 'styled-components'

import {fontDefault} from '@pleo-io/telescope-global-style'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {Skeleton} from '../skeleton'

export interface LinkProps
    extends AnchorHTMLAttributes<HTMLAnchorElement>,
        React.RefAttributes<HTMLAnchorElement> {
    /** The content of the link */
    children?: React.ReactNode
    /** inherit the parent's font-size */
    inherit?: boolean
    /** Add an Icon on the left side of the text */
    IconLeft?: React.FC<{size?: any}>
    /** Add an Icon on the right side of the text */
    IconRight?: React.FC<{size?: any}>
    /** Custom element or component to render instead of a standard anchor tag, mainly intended for usage with react-router */
    as?: any
    /** When (and only when) adding styles to the Link component with styled-components, use this instead of `as` */
    forwardedAs?: any
    /** Destination URL or path when the link is used as a react-router link */
    to?: RouterLinkProps['to']
    /** State data to pass along with the navigation when used as a react-router link */
    state?: RouterLinkProps['state']
    /** Whether to replace the current entry in the history stack when used as a react-router link */
    replace?: RouterLinkProps['replace']
    /**
     * Show a skeleton loading state
     * @default false
     */
    skeleton?: boolean
}

export const linkBaseStyles = css<{inherit?: boolean}>`
    ${focusRing('regular')}
    font-family: ${fontDefault};
    font-size: ${({inherit}) => (inherit ? 'inherit' : tokens.fontMedium)};
    cursor: pointer;
    transition: ${tokens.motionWithinSmallShort};
    transition-property: color;
    appearance: none;
    text-decoration: none;
`

export const linkColor = css`
    color: ${tokens.colorContentInteractiveLink};

    &:visited {
        color: ${tokens.colorContentInteractiveLink};
    }

    &:hover,
    &:active {
        color: ${tokens.colorContentInteractiveLinkHover};
    }
`

const SkeletonLoader = styled(Skeleton).attrs({loading: true, borderRadius: 4})``

const TRANSIENT_PROPS: (keyof LinkProps)[] = ['inherit', 'skeleton']

const shouldForwardProp = (propName: string) =>
    !TRANSIENT_PROPS.includes(propName as keyof LinkProps)

export const StyledLink = styled.a
    .withConfig({shouldForwardProp})
    .attrs<LinkProps & DataAttributes>((props) => ({
        ...(props.skeleton && {as: SkeletonLoader}),
        'data-telescope': 'link'
    }))<LinkProps>`
    ${linkBaseStyles}
    ${linkColor}
`

const Wrapper = styled.span`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: ${tokens.spacing8};
`

export const Link = forwardRef((props: LinkProps, ref) => {
    const {children, IconLeft, IconRight, skeleton = false, ...rest} = props
    return (
        <StyledLink data-telescope="link" ref={ref} skeleton={skeleton} {...rest}>
            {IconLeft || IconRight ? (
                <Wrapper>
                    {IconLeft && <IconLeft size={16} />}
                    {children}
                    {IconRight && <IconRight size={16} />}
                </Wrapper>
            ) : (
                children
            )}
        </StyledLink>
    )
})
