import styled, {css} from 'styled-components'
import type {ColorProps} from 'styled-system'
import {space, color} from 'styled-system'

import type {AvatarProps, BadgeVariant} from './avatar'
import {randomColor} from './random-color'
import type {AvatarSize} from './types'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'

const fontSizeMapping = {
    24: tokens.fontSmall,
    40: tokens.fontMedium,
    48: tokens.fontLarge,
    72: tokens.font2XLarge
}

export const iconSizeMapping = {
    24: 16,
    40: 24,
    48: 24,
    72: 32
}

export const outlineWidthMapping = {
    24: 1,
    40: 2,
    48: 2,
    72: 3
}

const TRANSIENT_PROPS = [...space.propNames!, ...color.propNames!]
const shouldForwardProp = (prop: string) => !TRANSIENT_PROPS.includes(prop)

export const AvatarCircle = styled.div.withConfig({shouldForwardProp})<{
    $size: AvatarSize
    $outlined?: boolean
}>`
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    cursor: default;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: ${({$size}) => $size}px;
    height: ${({$size}) => $size}px;
    font-size: ${({$size}) => fontSizeMapping[$size]};
    background-color: ${tokens.colorBackgroundStatic};
    box-shadow: ${({$outlined, $size}) =>
        $outlined ? `0 0 0 ${outlineWidthMapping[$size]}px ${tokens.colorBackgroundStatic}` : null};
    border-radius: ${tokens.arc99999};
    ${space}

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
`

export const AvatarCircleExcess = styled(AvatarCircle)`
    background: ${tokens.colorBackgroundStaticLoudest};
`

interface AvatarImageProps {
    src: AvatarProps['src']
    $name: AvatarProps['name']
}

export const AvatarImage = styled.img.attrs<AvatarImageProps>(({$name}) => ({
    alt: $name
}))<AvatarImageProps>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${tokens.arc99999};
`

export const AvatarInnerWrapper = styled.div.withConfig({shouldForwardProp})<
    {
        $name?: string
        $state?: AvatarProps['state']
    } & ColorProps
>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: ${tokens.arc99999};
    color: ${({$state}) =>
        // We need to force this in case color gets customized with a styled-system prop (more info in comment below)
        $state === 'disabled'
            ? ` ${tokens.colorContentStaticQuiet} !important`
            : tokens.colorContentStatic};

    ${({$state, $name = ''}) =>
        $state === 'placeholder'
            ? css`
                  border: 1px dashed ${tokens.colorBorderStaticLoud};
                  /* We need to force this for placeholder in case bg was customized
                  with a styled-system prop (see 'color' helper below)
                  https://linear.app/pleo/issue/DO-3543/improve-avatar-styled-system-props-api */
                  background-color: transparent !important;

                  & > * {
                      visibility: hidden;
                  }
              `
            : css`
                  background-color: ${randomColor($name)};
              `}

    ${color}

    ${({$state}) =>
        $state === 'disabled' &&
        css`
            /* This negation selector is used to target the other two possible children: initials (AvatarName) or icon */
            & > *:not(${AvatarImage}) {
                isolation: isolate;
            }

            &::before {
                content: '';
                inset: 0;
                position: absolute;
                background-color: ${tokens.colorBackgroundStatic};
                opacity: ${tokens.opacity50};
                border-radius: ${tokens.arc99999};
            }
        `}
`

const badgeDimensions: Record<AvatarSize, string> = {
    24: '28px',
    40: '24px',
    48: '28px',
    72: '32px'
}

type Position = 'bottom' | 'right'

const badgePosition: Record<AvatarSize, Record<Position, string>> = {
    24: {
        right: '-8px',
        bottom: '-8px'
    },
    40: {
        right: '-12px',
        bottom: '-8px'
    },
    48: {
        right: '-8px',
        bottom: '-8px'
    },
    72: {
        right: '-6px',
        bottom: '-6px'
    }
}

export const AvatarBadge = styled.div<{
    as: 'div' | 'button'
    $size: AvatarSize
    $variant: BadgeVariant
}>`
    ${focusRing('regular')};
    color: ${tokens.colorContentStatic};
    border: none;
    position: absolute;
    right: ${({$size}) => badgePosition[$size].right};
    bottom: ${({$size}) => badgePosition[$size].bottom};
    cursor: ${({as}) => (as === 'button' ? 'pointer' : 'initial')};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: ${({$size}) => badgeDimensions[$size]};
    height: ${({$size}) => badgeDimensions[$size]};
    background-color: ${({$variant}) =>
        $variant === 'attention'
            ? tokens.colorBackgroundPresentationalYellow
            : tokens.colorBackgroundStatic};
    border-radius: ${tokens.arc99999};
    box-shadow: 0 0 0 3px ${tokens.colorBackgroundStatic};
`
