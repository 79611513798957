import {DialogOverlay, DialogContent} from '@reach/dialog'
import type React from 'react'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {IconButton} from '../icon-button'
import type {TextProps} from '../text'
import {Text} from '../text'

import type {} from '@reach/utils'

type WithAs = {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

/* TODO agree on breakpoints */
export const BreakpointMobile = '778px'

export const ModalClose = styled(IconButton)`
    position: absolute;
    top: ${tokens.spacing8};
    right: ${tokens.spacing8};
`

const horizontalPadding = css`
    padding-right: ${tokens.spacing40};
    padding-left: ${tokens.spacing40};

    @media (max-width: ${BreakpointMobile}) {
        padding-right: ${tokens.spacing16};
        padding-left: ${tokens.spacing16};
    }
`

export const ModalTitle = styled(Text).attrs({
    variant: '2xlarge-accent',
    align: 'center',
    as: 'h2'
})`
    color: ${tokens.colorContentStatic};
    ${horizontalPadding}
`

export const ModalSubtitle = styled(Text).attrs({
    variant: 'xlarge-accent',
    align: 'center',
    as: 'h3'
})`
    font-size: ${tokens.fontMedium};
    ${horizontalPadding}
`

export interface ModalContentProps
    extends Omit<TextProps, 'variant' | 'as' | 'italic' | 'spacing'> {}

export const ModalContent = styled(Text).attrs<ModalContentProps & WithAs>((props) => ({
    as: props.as || 'div',
    variant: 'medium-default',
    align: props.align || 'center'
}))`
    ${horizontalPadding}
    color: ${({color}) => {
        if (!color) {
            return tokens.colorContentStatic
        } else if (color === 'inherit') {
            return 'inherit'
        }

        return tokens[color]
    }};
`

export const ModalIllustration = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ModalActions = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${tokens.spacing28};
    ${horizontalPadding}
`

export interface ModalFooterProps
    extends Omit<TextProps, 'variant' | 'as' | 'italic' | 'color' | 'spacing'> {}

export const ModalFooter = styled(Text).attrs<ModalFooterProps & WithAs>((props) => ({
    as: props.as || 'div',
    variant: 'medium-default',
    align: props.align || 'center'
}))`
    padding: ${tokens.spacing20} ${tokens.spacing40};
    color: ${tokens.colorContentStaticQuiet};
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight2};
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-bottom-right-radius: ${tokens.arc8};
    border-bottom-left-radius: ${tokens.arc8};
`

export interface ModalProps {
    /**
     * The width of the modal. Defaults to `500px`. Also accepts `auto`
     */
    size?: string
}

export const Modal = styled(DialogContent).withConfig({
    shouldForwardProp: (propName) => !['size', 'paddingTop'].includes(propName)
})<ModalProps>`
    position: relative;
    width: ${({size}) => size ?? '500px'};
    margin: auto;
    padding-top: ${tokens.spacing24};
    background: ${tokens.colorBackgroundStatic};
    border-radius: ${tokens.arc8};
    outline: none;

    ${ModalTitle}, ${ModalSubtitle}, ${ModalContent}, ${ModalIllustration}, ${ModalActions} {
        margin-bottom: ${tokens.spacing24};
    }

    ${ModalFooter} {
        margin-bottom: 0;
    }

    @media (max-width: ${BreakpointMobile}) {
        /* TODO agree on breakpoints + mobile first vs desktop first */
        width: 100%;
        margin-top: auto;
    }
`

export const ModalBackdrop = styled(DialogOverlay)<{$dangerouslySetZIndexValue?: number}>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: ${tokens.spacing10};
    overflow: auto;
    background: ${tokens.colorBackgroundStaticBackdrop};
    backface-visibility: hidden;
    scroll-behavior: smooth;

    ${({$dangerouslySetZIndexValue}) =>
        $dangerouslySetZIndexValue &&
        css`
            z-index: ${$dangerouslySetZIndexValue};
        `}
`
