import React from 'react'

import {CustomColorSchemeTokensGlobalStyle} from '../../tokens/custom'
import {DialogTriggerContextProvider} from '../../utils/dialog-trigger-provider'
import type {LocalisationContextType} from '../../utils/localisation'
import {LocalisationProvider} from '../../utils/localisation'
import {TooltipProvider} from '../tooltip'

// For usage in e.g. storybook, testing setup, and our website
export const __dummyTelescopeProviderTranslations: LocalisationContextType = {
    Callout: {
        CloseButton: 'Dismiss'
    },
    Modal: {
        CloseButton: 'Close'
    },
    FormControl: {
        Error: 'Error'
    },
    Loading: {
        LoadingLabel: 'Loading'
    },
    ModalSplit: {
        BackButton: 'Back',
        StepButton: 'Step'
    },
    statusLabels: {
        neutral: 'Information',
        info: 'Information',
        positive: 'Success',
        warning: 'Warning',
        negative: 'Error',
        discover: 'New'
    }
}

type Props = {
    children: React.ReactNode
    translations: LocalisationContextType
}
export const TelescopeProvider = ({children, translations}: Props) => {
    return (
        <LocalisationProvider value={translations}>
            <DialogTriggerContextProvider>
                <TooltipProvider>
                    <CustomColorSchemeTokensGlobalStyle />
                    {children}
                </TooltipProvider>
            </DialogTriggerContextProvider>
        </LocalisationProvider>
    )
}
