// Implementation heavily inspired by Radix Themes Skeleton component
// https://github.com/radix-ui/themes/blob/main/packages/radix-ui-themes/src/components/skeleton.tsx
import {Slot} from '@radix-ui/react-slot'
import * as React from 'react'
import styled, {keyframes} from 'styled-components'
import {borderRadius} from 'styled-system'

import type {RadiusValues} from '@pleo-io/telescope-tokens/dist/radius.types'

import {tokens, customColorSchemeTokens} from '../../tokens'

const pulse = keyframes`
  from {
    background-color: ${customColorSchemeTokens.colorBackgroundSkeletonFrom};
  }
  to {
    background-color: ${customColorSchemeTokens.colorBackgroundSkeletonTo};
  }
`

const pulseLoud = keyframes`
  from {
    background-color: ${customColorSchemeTokens.colorBackgroundSkeletonFromLoud};
  }
  to {
    background-color: ${customColorSchemeTokens.colorBackgroundSkeletonToLoud};
  }
`

const SkeletonWrapper = styled.div.withConfig({
    shouldForwardProp: (prop: string) => prop !== 'borderRadius'
})<{
    borderRadius?: RadiusValues
    $height?: string
    $width?: string
    $loud?: boolean
}>`
    animation: ${({$loud}) => ($loud ? pulseLoud : pulse)} 1000ms infinite alternate-reverse !important;
    ${borderRadius};
    background-image: none !important;
    background-clip: border-box !important;
    border-color: transparent !important;
    box-shadow: none !important;
    color: transparent !important;
    outline: none !important;
    pointer-events: none !important;
    user-select: none !important;
    cursor: default !important;
    height: ${({$height}) => ($height ? `${$height} !important` : null)};
    width: ${({$width}) => ($width ? `${$width} !important` : null)};
    box-decoration-break: clone !important;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-decoration-break: clone !important;

    &:empty {
        display: block;
        height: ${tokens.spacing24};
    }

    > *,
    &::after,
    &::before {
        visibility: hidden !important;
    }
`

type SkeletonElement = React.ElementRef<'div'>
type SkeletonProps = {
    className?: string
    loading?: boolean
    children?: React.ReactNode
    width?: string
    height?: string
    borderRadius?: RadiusValues
    loud?: boolean
}

export const Skeleton = React.forwardRef<SkeletonElement, SkeletonProps>((props, forwardedRef) => {
    const {children, className, loading = true, width, height, borderRadius, loud} = props

    if (!loading) {
        return <>{children}</>
    }

    return (
        <SkeletonWrapper
            as={React.isValidElement(children) ? Slot : 'span'}
            ref={forwardedRef}
            $loud={loud}
            $width={width}
            borderRadius={borderRadius}
            $height={height}
            aria-hidden
            className={className}
            tabIndex={-1}
            {...{inert: ''}}
        >
            {children}
        </SkeletonWrapper>
    )
})
