import * as RadixAccordion from '@radix-ui/react-accordion'
import styled, {css} from 'styled-components'

import {ChevronDown} from '@pleo-io/telescope-icons'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {Inline} from '../inline'
import {Stack} from '../stack'

export const SpacedAccordion = styled(RadixAccordion.Root)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing16};
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
`

export const Item = styled(RadixAccordion.Item)`
    width: 100%;
    overflow: hidden;
    background-color: ${tokens.colorContentInteractiveInverse};
    border: ${tokens.borderInteractiveQuiet};
    border-radius: ${tokens.arc8};
`

const buttonReset = css`
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    line-height: inherit;
    text-align: inherit;
    background-color: transparent;
    border-width: 0;
`

export const Trigger = styled(RadixAccordion.Trigger)`
    ${focusRing('regular')}
    ${buttonReset}
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${tokens.spacing16};
    padding: ${tokens.spacing16} ${tokens.spacing24};
    color: ${tokens.colorContentInteractive};
    cursor: pointer;
    transition: background ${tokens.motionWithinSmallShort};

    > * {
        flex: 1 0 auto;
    }

    [data-disabled] & {
        background-color: ${tokens.colorBackgroundInteractiveDisabled};
        cursor: not-allowed;

        * {
            color: ${tokens.colorContentInteractiveDisabled};
        }
    }

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
    }
`

export const Content = styled(RadixAccordion.Content)`
    overflow: hidden;
    border-top: ${tokens.borderInteractiveQuiet};

    &[data-state='open'] {
        animation: slideDown ${tokens.smoothOut};
    }

    &[data-state='closed'] {
        animation: slideUp ${tokens.smoothOut};
    }

    @media (prefers-reduced-motion) {
        &[data-state='open'],
        &[data-state='closed'] {
            animation: none;
        }
    }

    @keyframes slideDown {
        from {
            height: 0;
        }

        to {
            /* stylelint-disable-next-line custom-property-pattern */
            height: var(--radix-accordion-content-height);
        }
    }

    @keyframes slideUp {
        from {
            /* stylelint-disable-next-line custom-property-pattern */
            height: var(--radix-accordion-content-height);
        }

        to {
            height: 0;
        }
    }
`
export const Chevron = styled(ChevronDown).attrs({size: 16})`
    transition: ${tokens.motionWithinSmallLong};

    @media (prefers-reduced-motion) {
        transition: none;
    }

    ${Trigger}[data-state='open'] & {
        transform: rotate(-180deg);
    }
`

export const ContentWrapper = styled.div`
    padding: ${tokens.spacing24};
`

export const TitleWrapper = styled(Inline).attrs({as: 'span', space: 10})`
    flex: 1 1 auto;
    width: 100%;

    * {
        max-width: 100%;
    }
`

export const TitleContent = styled(Stack).attrs({as: 'span', space: 4})`
    svg + & {
        padding: 1px 0 0;
    }
`

export const Footer = styled.div`
    padding: ${tokens.spacing12} ${tokens.spacing24};
    border-radius: 0 0 ${tokens.arc8} ${tokens.arc8};
    border-top: ${tokens.borderStatic};
    background-color: ${tokens.colorBackgroundStaticLoud};
`

export const Header = styled.h2`
    margin: 0;
`
