import type {CSSProperties, DataAttributes} from 'styled-components'
import styled, {css} from 'styled-components'
import type {FlexboxProps} from 'styled-system'
import {flexbox, space, border, borderRadius} from 'styled-system'

import type {SpacingValues} from '@pleo-io/telescope-tokens'

import type {BoxProps} from '../box'

const alignXKeys = ['left', 'center', 'right'] as const
type AlignX = (typeof alignXKeys)[number]
const alignXValues: {[key in AlignX]: string} = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
}

const alignYKeys = ['top', 'center', 'bottom'] as const
type AlignY = (typeof alignYKeys)[number]
const alignYValues: {[key in AlignY]: string} = {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end'
}

export interface InlineProps extends FlexboxProps, BoxProps {
    /**
     * The space between each item. Default 0.
     */
    space?: 0 | SpacingValues

    /**
     * Shorthand for `alignX alignY` in a single prop.
     */
    align?: string
    /**
     * Accepts all valid values of flex justify-content.
     */
    alignX?: AlignX | CSSProperties['justifyContent']
    /**
     * Accepts all valid values of flex align-items and shorthands `top`, `center`, `bottom`
     */
    alignY?: AlignY | CSSProperties['alignItems']

    /**
     * Allow children to wrap if their total size is larger than the parent.
     */
    wrap?: boolean

    /**
     * Set all children to take all the available space
     */
    stretchChildren?: boolean
}

const wrap = (props: InlineProps) =>
    props.wrap &&
    css<InlineProps>`
        flex-wrap: wrap;
    `

const stretchChildren = (props: InlineProps) =>
    props.stretchChildren &&
    css`
        & > * {
            width: 100%;
        }
    `

const TRANSIENT_PROPS = [
    'space',
    'align',
    'alignX',
    'alignY',
    'wrap',
    'stretchChildren',
    ...flexbox.propNames!,
    ...space.propNames!,
    ...border.propNames!,
    ...borderRadius.propNames!
]
const shouldForwardProp = (prop: string) => !TRANSIENT_PROPS.includes(prop)

export const Inline = styled.div
    .withConfig({shouldForwardProp})
    .attrs<InlineProps & DataAttributes>(({align = '', alignX, alignY, space, ...props}) => ({
        alignX: alignX ?? align.split(' ')[0],
        alignY: alignY ?? align.split(' ')[1],
        space: space ?? 0,
        'data-telescope': props['data-telescope'] ?? 'inline'
    }))<InlineProps & DataAttributes>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: ${({alignY = 'top'}) =>
        alignYKeys.includes(alignY as AlignY) ? alignYValues[alignY as AlignY] : alignY};
    justify-content: ${({alignX = 'left'}) =>
        alignXKeys.includes(alignX as AlignX) ? alignXValues[alignX as AlignX] : alignX};
    box-sizing: border-box;

    ${({space}) =>
        space &&
        css`
            column-gap: ${space}px;
        `};
    ${wrap}
    ${stretchChildren}
    ${flexbox}
    ${space}
`

Inline.displayName = 'Inline'
