import * as RadixPopover from '@radix-ui/react-popover'
import React from 'react'
import styled from 'styled-components'

import {Close} from '@pleo-io/telescope-icons'

import * as Styled from './popover.styles'

import {tokens} from '../../tokens'

export type PopoverProps = RadixPopover.PopoverProps
export const Popover = (props: PopoverProps) => {
    return <RadixPopover.Root {...props}>{props.children}</RadixPopover.Root>
}

const PopoverTrigger = (props: Omit<RadixPopover.PopoverTriggerProps, 'asChild'>) => {
    return (
        <RadixPopover.Trigger {...props} asChild>
            {props.children}
        </RadixPopover.Trigger>
    )
}

const PopoverAnchor = (props: React.PropsWithChildren<RadixPopover.PopoverAnchorProps>) => (
    <RadixPopover.Anchor {...props}>{props.children}</RadixPopover.Anchor>
)

export type PopoverContentProps = RadixPopover.PopoverContentProps & {
    matchWidth?: boolean
    portalled?: boolean
}

enum SIDE_OPTIONS {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right'
}

const PopoverContent = ({
    matchWidth = false,
    side = SIDE_OPTIONS.Bottom,
    sideOffset = 2,
    portalled = true,
    ...props
}: PopoverContentProps) => {
    const width = matchWidth ? `var(--radix-popover-trigger-width)` : '100%'

    const Wrapper = portalled ? RadixPopover.Portal : React.Fragment

    return (
        <Wrapper>
            <Styled.Content
                data-telescope="popover"
                {...props}
                side={side}
                $width={width}
                arrowPadding={parseInt(tokens.arc8)}
                sideOffset={sideOffset}
            >
                <Styled.ContentInner>{props.children}</Styled.ContentInner>
            </Styled.Content>
        </Wrapper>
    )
}

export const PopoverCloseIcon = () => <Close size={16} mr={'4px'} />

const PopoverClose = (props: Omit<RadixPopover.PopoverCloseProps, 'asChild'>) => (
    <RadixPopover.Close {...props} asChild>
        <Styled.ActionButton>
            <PopoverCloseIcon /> {props.children}
        </Styled.ActionButton>
    </RadixPopover.Close>
)

const PopoverActionButton = Styled.ActionButton

const PopoverArrow = (
    props: React.PropsWithChildren<Omit<RadixPopover.PopoverArrowProps, 'asChild'>>
) => {
    return (
        <RadixPopover.Arrow {...props} asChild>
            <Styled.TriangleWrapper>
                <Styled.Triangle />
            </Styled.TriangleWrapper>
        </RadixPopover.Arrow>
    )
}

const PopoverControlsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: ${tokens.spacing6};
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-top: ${tokens.borderStatic};
    border-bottom-right-radius: ${tokens.arc8};
    border-bottom-left-radius: ${tokens.arc8};
`

Popover.Trigger = PopoverTrigger
Popover.Anchor = PopoverAnchor
Popover.Content = PopoverContent
Popover.Close = PopoverClose
Popover.ActionButton = PopoverActionButton
Popover.Arrow = PopoverArrow
Popover.ControlsContainer = PopoverControlsContainer
