import {ErrorMessage, useField} from 'formik'
import type {FC} from 'react'
import React from 'react'

import {FormControl} from '../form-control'
import type {InputProps} from '../input'
import {Input} from '../input'

export const FormikInput: FC<InputProps> = (props) => {
    const [field, meta] = useField(props.name)

    return (
        <Input
            isInvalid={!!(meta.error && meta.touched)}
            renderError={() => <ErrorMessage name={props.name} component={FormControl.Error} />}
            data-telescope="formik-input"
            {...field}
            {...props}
        />
    )
}

FormikInput.displayName = 'FormikInput'
