import {useField} from 'formik'
import React from 'react'

import type {CheckboxProps} from '../checkbox'
import {Checkbox} from '../checkbox'

interface Props extends CheckboxProps {
    name: string
}

export const FormikCheckbox = ({onChange, onClick, ...props}: Props) => {
    const [{name, value}, , {setTouched, setValue}] = useField(props)

    return (
        <Checkbox
            {...props}
            name={name}
            checked={value}
            onClick={onClick}
            onChange={(e) => {
                setTouched(true)
                setValue(e.target.checked)
                onChange && onChange(e)
            }}
            data-telescope="formik-checkbox"
        />
    )
}

FormikCheckbox.displayName = 'FormikCheckbox'
