import * as Popover from '@radix-ui/react-popover'
import styled, {css, keyframes} from 'styled-components'
import {space} from 'styled-system'

import {tokens} from '../../tokens'
import {NakedButton} from '../button'
import {focusRing} from '../focus-ring'

// This is an arbitrary value. I felt like it was enough
// to draw attention to the popover without becoming annoying
const translateDistance = '10px'

const slideRightAndFade = keyframes({
    '0%': {transform: `translateX(-${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateX(0)', opacity: 1}
})

const slideDownAndFade = keyframes({
    '0%': {transform: `translateY(-${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateY(0)', opacity: 1}
})

const slideLeftAndFade = keyframes({
    '0%': {transform: `translateX(${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateX(0)', opacity: 1}
})

const fadeOut = keyframes({
    '0%': {opacity: 1},

    '100%': {opacity: 0}
})

const slideUpAndFade = keyframes({
    '0%': {transform: `translateY(${translateDistance})`, opacity: 0},

    '100%': {transform: 'translateY(0)', opacity: 1}
})

type StyledContentProps = Popover.PopoverContentProps & {
    $width?: string
}

const animationStyles = css`
    transform-origin: 'var(--radix-popover-content-transform-origin)';

    /* The content should idealy be shown within 0.1 seconds after
    delay. The fast token is the closest we have to this
    https://ux.stackexchange.com/a/119975 */
    animation-duration: ${tokens.fast};
    animation-timing-function: 'cubic-bezier(0.16, 1, 0.3, 1)';

    &[data-side='top'] {
        animation-name: ${slideDownAndFade};
    }

    &[data-side='right'] {
        animation-name: ${slideLeftAndFade};
    }

    &[data-side='bottom'] {
        animation-name: ${slideUpAndFade};
    }

    &[data-side='left'] {
        animation-name: ${slideRightAndFade};
    }

    &[data-state='closed'] {
        animation-name: ${fadeOut};
    }
`

export const ContentInner = styled.div`
    background-color: ${tokens.colorBackgroundStatic};

    /* Applying the border directly to the Content component
    causes flickering of the popover Arrow */
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowElevateQuiet};
`

export const Content = styled(Popover.Content)<StyledContentProps>`
    width: ${(props) => props.$width};
    z-index: ${tokens.zIndexPopover};
    ${focusRing('regular')};
    ${space}
    ${animationStyles}
`

export const ActionButton = styled(NakedButton)`
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    padding: ${tokens.spacing10};
    color: ${tokens.colorContentInteractive};
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight1};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
    }

    &:disabled {
        color: ${tokens.colorContentInteractiveDisabled};
        cursor: not-allowed;
    }
`

export const arrowWidth = 12

export const TriangleWrapper = styled.div`
    /* Calculate diagonal distance between corners of the square */
    box-sizing: border-box;
    height: ${(arrowWidth * Math.sqrt(2)) / 2}px;
`

export const Triangle = styled.div`
    box-sizing: border-box;
    width: ${arrowWidth}px;
    height: ${arrowWidth}px;
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    transform: translateY(calc(-50% - 1px)) rotate(-45deg);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
`
