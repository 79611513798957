import {ErrorMessage, useField} from 'formik'
import type {FC} from 'react'
import React from 'react'

import type {CurrencyInputProps} from '../currency-input'
import {CurrencyInput} from '../currency-input'
import {FormControl} from '../form-control'

type FormikCurrencyInputProps = Omit<CurrencyInputProps, 'renderInput'>

export const FormikCurrencyInput: FC<FormikCurrencyInputProps> = (props) => {
    const [field, meta] = useField(props.name)

    return (
        <CurrencyInput
            isInvalid={!!(meta.error && meta.touched)}
            renderError={() => <ErrorMessage name={props.name} component={FormControl.Error} />}
            data-telescope="formik-currency-input"
            {...field}
            {...props}
        />
    )
}

FormikCurrencyInput.displayName = 'FormikCurrencyInput'
