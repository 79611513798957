import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, {css} from 'styled-components'

import {tokens} from '../../tokens'
import {focusRing} from '../focus-ring'
import {Text} from '../text'

export const StyledActionMenuTrigger = styled(RadixDropdownMenu.Trigger)``

export const StyledActionMenuSeparator = styled(RadixDropdownMenu.Separator)`
    width: 100%;
    height: ${tokens.sizeBorderDefault};
    margin-block: ${tokens.spacing8};
    background-color: ${tokens.colorBorderStatic};
`

export const StyledActionMenuContent = styled(RadixDropdownMenu.Content)`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 16ch;
    max-width: 32ch;
    padding: ${tokens.spacing8};
    background: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowRaiseQuiet};

    &[data-state='open'] {
        animation: ${tokens.motionEnterSmallShort} open;
    }

    &[data-state='closed'] {
        animation: ${tokens.motionExitSmallShort} close;
    }

    @media (prefers-reduced-motion) {
        &[data-state='open'],
        &[data-state='closed'] {
            animation: none;
        }
    }

    @keyframes open {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes close {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
`

const BaseActionMenuItem = css`
    box-sizing: border-box;
    ${focusRing('regular')}
    display: flex;
    align-items: flex-start;
    gap: ${tokens.spacing8};
    padding: ${tokens.spacing6} ${tokens.spacing8};
    user-select: none;
    cursor: pointer;
    font-size: ${tokens.fontMedium};
    line-height: ${tokens.lineHeight3};
    border-radius: ${tokens.arc4};
    transition: color, background-color ${tokens.motionWithinSmallShort};

    &[aria-disabled] {
        color: ${tokens.colorContentInteractiveDisabled};
        cursor: not-allowed;
    }
`

export const DescriptionText = styled(Text).attrs({variant: 'small-subtle'})<{
    $isDestructive?: boolean
}>`
    color: ${({$isDestructive}) => {
        return $isDestructive ? tokens.colorContentNegative : tokens.colorContentInteractiveQuiet
    }};
`

export const StyledActionMenuItem = styled(RadixDropdownMenu.Item)<{$isDestructive?: boolean}>`
    ${BaseActionMenuItem}

    color: ${({$isDestructive}) => {
        return $isDestructive ? tokens.colorContentNegative : tokens.colorContentInteractive
    }};

    &:hover {
        background-color: ${({$isDestructive}) => {
            return $isDestructive
                ? tokens.colorBackgroundNegativeQuietHover
                : tokens.colorBackgroundInteractiveHover
        }};
    }

    &:hover,
    &:hover ${DescriptionText} {
        color: ${({$isDestructive}) => {
            return $isDestructive
                ? tokens.colorContentNegativeHover
                : tokens.colorContentInteractiveHover
        }};
    }
`

export const StyledActionMenuRadioGroup = styled(RadixDropdownMenu.RadioGroup)`
    display: flex;
    flex-direction: column;
`

export const StyledActionMenuRadioItem = styled(RadixDropdownMenu.RadioItem)`
    ${BaseActionMenuItem}
    display: flex;
    justify-content: space-between;
    color: ${tokens.colorContentInteractive};

    &:hover {
        color: ${tokens.colorContentInteractiveHover};
        background-color: ${tokens.colorBackgroundInteractiveHover};
    }
`

export const IconWrapper = styled('div')`
    display: grid;
    place-content: center;
    margin-top: ${tokens.spacing4};
`
