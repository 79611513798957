import type {DialogContentProps} from '@reach/dialog'
import type {HTMLMotionProps} from 'framer-motion'
import {AnimatePresence, motion} from 'framer-motion'
import type {HTMLAttributes, ReactNode} from 'react'
import React from 'react'

import {Close} from '@pleo-io/telescope-icons'

import * as s from './modal.styles'
import {
    ModalIllustration,
    ModalTitle,
    ModalSubtitle,
    ModalContent,
    ModalActions,
    ModalFooter
} from './modal.styles'

import {tokens} from '../../tokens'
import {useLocalisation} from '../../utils/localisation'
import type {IconButtonProps} from '../icon-button'
type OnDismiss = ((event?: React.SyntheticEvent<Element, Event> | undefined) => void) | undefined

export const ModalClose = ({
    onClick,
    ...props
}: {
    onClick: OnDismiss
} & Omit<
    IconButtonProps,
    'variant' | 'Icon' | 'size' | 'tooltipProps' | 'onClick' | 'aria-label'
>) => {
    const translations = useLocalisation()

    return (
        <s.ModalClose
            Icon={Close}
            onClick={onClick}
            tooltipProps={{
                dangerouslyOmitTooltip: true,
                'aria-label': translations.Modal.CloseButton
            }}
            variant="secondary"
            size="medium"
            {...props}
        />
    )
}

ModalClose.displayName = 'ModalClose'

const backdropAnimationVariants = {
    initial: {
        opacity: 0,
        transition: {
            opacity: {
                duration: tokens.motionDurationModerate,
                easing: tokens.motionEasingExitScreen
            }
        }
    },
    enter: {
        opacity: 1,
        transition: {
            opacity: {
                duration: tokens.motionDurationSlow,
                easing: tokens.motionEasingEnterScreen
            }
        }
    }
}

const modalAnimationVariants = {
    initial: {
        opacity: 0,
        transition: {
            opacity: {
                duration: tokens.motionDurationModerate,
                easing: tokens.motionEasingExitScreen
            }
        }
    },
    enter: {
        opacity: 1,
        transition: {
            opacity: {
                delay: 0.06,
                duration: tokens.motionDurationSlow,
                easing: tokens.motionEasingEnterScreen
            }
        }
    }
}

export type ModalProps = HTMLMotionProps<'div'> &
    HTMLAttributes<HTMLDivElement> &
    s.ModalProps &
    DialogContentProps & {
        // interface ModalProps extends DialogContentProps, HTMLAttributes<HTMLDivElement>, s.ModalProps {
        children: ReactNode
        /**
         * Handle zoom/pinch gestures on iOS devices when scroll locking is enabled.
         * Defaults to `false`.
         */
        allowPinchZoom?: boolean
        /**
         * By default the first focusable element will receive focus when the dialog opens but you can provide a ref to focus instead.
         */
        initialFocusRef?: React.RefObject<any>
        /**
         * Controls whether or not the dialog is open.
         */
        isOpen?: boolean
        /**
         * This function is called whenever the user hits "Escape" or clicks outside
         * the dialog. _It's important to close the dialog `onDismiss`_.
         *
         * The only time you shouldn't close the dialog on dismiss is when the dialog requires a choice and none of them are "cancel".
         * For example, perhaps two records need to be merged and the user needs to pick the surviving record.
         * Neither choice is less destructive than the other, in these cases you may want to alert the user they need to a make a choice on dismiss instead of closing the dialog.
         */
        onDismiss?: OnDismiss
        /**
         * See [Reach UI docs](https://reach.tech/dialog#dialogoverlay-dangerouslybypassfocuslock)
         */
        dangerouslyBypassFocusLock?: boolean | undefined
        /**
         * See [Reach UI docs](https://reach.tech/dialog#dialogoverlay-dangerouslybypassscrolllock)
         */
        dangerouslyBypassScrollLock?: boolean | undefined
        /**
         * Temporary fix to overlapping modals. Use with care.
         */
        dangerouslySetZIndexValue?: number
        /**
         * Defines a string value that labels the current element.
         */
        'aria-label'?: string
        /**
         * Identifies the element (or elements) that labels the current element.
         */
        'aria-labelledby'?: string
    }

// Detect if the component is being rendered in Storybook. We use it to avoid unwanted focus lock.
const isStorybookMode = () => typeof window !== 'undefined' && Boolean(window.IS_STORYBOOK)

export const Modal = ({
    children,
    allowPinchZoom,
    initialFocusRef,
    isOpen = true,
    onDismiss,
    dangerouslyBypassFocusLock,
    dangerouslyBypassScrollLock,
    dangerouslySetZIndexValue,
    size,
    ...props
}: ModalProps) => {
    return (
        <AnimatePresence>
            {isOpen ? (
                <AnimatedModalBackdrop
                    variants={backdropAnimationVariants}
                    initial="initial"
                    animate="enter"
                    exit="initial"
                    isOpen={isOpen}
                    onDismiss={onDismiss}
                    allowPinchZoom={allowPinchZoom}
                    initialFocusRef={initialFocusRef}
                    // Avoid grabbing focus from Storybook search input, read more in https://github.com/reach/reach-ui/issues/536
                    dangerouslyBypassFocusLock={isStorybookMode() || dangerouslyBypassFocusLock}
                    dangerouslyBypassScrollLock={dangerouslyBypassScrollLock}
                    $dangerouslySetZIndexValue={dangerouslySetZIndexValue}
                >
                    <AnimatedModal
                        variants={modalAnimationVariants}
                        initial="initial"
                        animate="enter"
                        exit="initial"
                        aria-label={props['aria-label']}
                        aria-labelledby={props['aria-labelledby']}
                        size={size}
                        {...props}
                        data-telescope="modal"
                    >
                        {children}
                    </AnimatedModal>
                </AnimatedModalBackdrop>
            ) : null}
        </AnimatePresence>
    )
}

const AnimatedModalBackdrop = motion(s.ModalBackdrop)
const AnimatedModal = motion(s.Modal)

Modal.displayName = 'Modal'

export {
    ModalIllustration,
    ModalTitle,
    ModalSubtitle,
    ModalContent,
    ModalActions,
    ModalFooter
} from './modal.styles'

Modal.Illustration = ModalIllustration
Modal.Title = ModalTitle
Modal.Subtitle = ModalSubtitle
Modal.Content = ModalContent
Modal.Actions = ModalActions
Modal.Footer = ModalFooter
Modal.Close = ModalClose
